@mixin title-line {
    content: '';
    display: block;
    width: 20px;
    height: 3px;
    border-radius: 20px;
    background-color: $main;
}

@mixin arrows {
    position: absolute;
    top: 0;
    width: 10px;
    height: 1px;
    background-color: white;
    border-radius: 25px;
}

@mixin price {
    width: fit-content;
    padding: 5px 8px;
    color: #fff;
    font-weight: 500;
    font-size: 0.8em;
    background-color: $main;
    border-radius: 12px;
}
