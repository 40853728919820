.header {
    .header__logo {
        width: 80px;
        margin: 5px 15px;
    }
    .burger {
        top: 15px;
        right: 0px;
        margin-right: 15px;
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40px;
        height: 30px;
        z-index: 3;
        .burger__line {
            width: 100%;
            height: 4px;
            background-color: $main;
            border-radius: 15px;
            transition: 0.5s;
            transform-origin: 0% 50%;
        }
    }
    .burger--active {
        .burger__line {
            background-color: rgb(136, 196, 253);
        }
        .burger__line:nth-child(2) {
            display: none;
        }
        .burger__line:nth-child(1) {
            transform: rotate(45deg) translateX(-1px);
        }
        .burger__line:nth-child(3) {
            transform: rotate(-45deg) translateX(-2px);
        }
    }
    .header__nav {
        position: fixed;
        top: 0;
        right: 0;
        width: 70%;
        background-color: $main;
        height: 100vh;
        z-index: 2;
        transform: translateX(100%);
        transition: 0.5s;

        .nav__list {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 20%;

            .list__item {
                font-weight: 300;
                font-size: 1.2em;
                list-style: none;
                transition: 0.1s;
                color: #fff;
                font-family: $font;
                margin-top: 15%;

                a {
                    color: #fff;
                    font-family: $font;
                    text-decoration: none;
                }
            }
            .list__item:hover {
                padding-bottom: 2px;
                border-bottom: 1px solid $main;
            }

            .offer {
                display: flex;
                flex-direction: column;
                align-items: center;
                .fa-angle-down {
                    transition: 0.3s;
                }
                .offer__list {
                    display: none;

                    .offer__list-item {
                        list-style-type: none;
                        text-transform: uppercase;
                        margin-top: 15%;
                    }
                }
            }
            .offer--active {
                .fa-angle-down {
                    transform: rotate(180deg) translateY(10%);
                }
                .offer__list {
                    display: block;
                }
            }
        }
    }
    .header__nav--active {
        transform: translateX(0%);
    }
}

@media (orientation: landscape) {
    .header {
        .header__nav {
            .nav__list {
                margin-top: 10%;
                .list__item {
                    margin-top: 3%;
                }
            }
        }
    }
}

@media (orientation: landscape) and (min-width: 1300px) {
    .header {
        position: fixed;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        width: 100%;
        padding: 0 20%;
        z-index: 3;
        box-shadow: 0px 0px 10px 0px rgb(192, 192, 192);

        .header__logo {
            margin: 5px 0px;
            width: 150px;
        }
        .burger {
            display: none;
        }
        .header__nav {
            position: static;

            height: auto;
            display: flex;
            flex-basis: 45%;
            background-color: transparent;
            transform: translateX(0);

            .nav__list {
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                margin: 0;
                height: auto;

                .list__item {
                    a {
                        color: $main;
                        font-weight: 400;
                    }
                }
                .offer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    color: $main;
                    font-weight: 400;
                    cursor: pointer;
                    margin-bottom: 0;

                    .offer__list {
                        position: absolute;
                        top: 105%;
                        width: 200%;
                        text-align: center;
                        display: none;
                        background-color: rgb(255, 255, 255);

                        .offer__list-item {
                            list-style-type: none;
                            text-transform: uppercase;
                            margin: 0;
                            padding: 6% 12%;
                            border-top: 1px solid #aaa;
                            margin-top: 0;
                        }
                        .offer__list-item:hover {
                            background-color: rgb(241, 248, 253);
                        }
                        .offer__list-item:first-child {
                            border-top: none;
                        }
                    }
                }
            }
            .offer:hover {
                .offer__list {
                    display: block;
                }
            }
        }
    }
}
