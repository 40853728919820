.wrapper {
    width: 80%;
    margin: 0 auto;
}
.about-us {
    margin-top: 20%;

    .about-us__title {
        font-family: $font;
        color: $black;
    }
    .about-us__title::after {
        @include title-line;
    }
    .about-us__content {
        margin-top: 10%;
        font-family: $font;
        color: $black;
        font-size: 0.8em;
        line-height: 200%;
        text-align: justify;
        .about-us__content-p {
            margin: 3% 0;
        }
        .about-us__content-sign {
            margin: 0 0;
        }
        .about-us__content-lawyer {
            line-height: 150%;
        }
        .about-us__content--bold {
            font-weight: 500;
        }
    }
}

@media (orientation: portrait) and (min-width: 700px) {
    .wrapper {
        width: 70%;
    }
    .about-us {
        font-size: 22px;
    }
}

@media (orientation: landscape) {
    .about-us {
        font-size: 18px;
        margin-top: 10%;
        .about-us__content {
            margin-top: 5%;
        }
    }
}
@media (orientation: landscape) and (min-width: 1300px) {
    .wrapper {
        width: 55%;
    }
    .about-us {
        padding-bottom: 20vh;
        font-size: 22px;
    }
    .photo {
        left: 0;
        position: absolute;

        width: 100%;
        height: 40vh;

        background-image: url(/source/img/ny_banner.jpg);
        background-size: cover;
        background-attachment: fixed;
        filter: brightness(70%);
    }
}
