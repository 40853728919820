.footer {
    background-color: $black;
    color: white;
    font-family: $font;
    display: flex;

    font-weight: 300;
    flex-direction: column;
    justify-content: space-between;
    padding: 10% 10% 5% 10%;
    .footer__logo {
        width: 80%;
        margin: 0 auto;
    }
    .footer__data {
        line-height: 250%;
        padding: 10% 0;
        .fas {
            margin-right: 3%;
        }
    }

    .footer__nav {
        padding: 5% 0 10% 0;
        line-height: 250%;
        list-style-type: none;
        a {
            color: #fff;
            text-decoration: none;
        }
        a:hover {
            border-bottom: 1px solid #fff;
        }
    }

    .footer__dev {
        font-size: 0.8em;
        text-align: center;
        a {
            color: white;
        }
        a:hover {
            text-decoration: none;
        }
    }
}

@media (orientation: portrait) and (min-width: 700px) {
    .footer {
        font-size: 20px;
    }
}

@media (orientation: landscape) {
    .footer {
        font-size: 10px;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;

        //justify-content: center;
        .footer__logo {
            order: 2;
            width: 33%;
            height: 100%;
        }
        .footer__data {
            padding: 0;
            order: 1;
            width: 33%;
        }
        .footer__nav {
            order: 3;
            width: 33%;
            padding: 0;
            text-align: right;
        }
        .footer__dev {
            order: 4;
            margin-top: 50px;
        }
    }
}

@media (orientation: landscape) and (min-width: 800px) {
    .footer {
        font-size: 12px;
    }
}

@media (orientation: landscape) and (min-width: 1000px) {
    .footer {
        font-size: 14px;
    }
}

@media (orientation: landscape) and (min-width: 1300px) {
    .footer {
        font-size: 16px;
        padding: 60px 10% 25px 10%;
        .footer__logo {
            width: 15%;
        }
        .footer__data,
        .footer__nav {
            line-height: 220%;
            width: 25%;
        }
        .footer__dev {
            width: 100%;
        }
    }
}
