.best-offers {
    margin-top: 20%;
    a:link,
    a:visited {
        text-decoration: none;
    }
    .best-offers__title {
        font-family: $font;
        color: $black;
    }
    .best-offers__title::after {
        @include title-line;
    }
    .best-offers__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 30px 0;
        width: 100%;

        box-shadow: $shadow;
        border-radius: 10px;
        font-family: $font;
        color: $black;

        .hide {
            overflow: hidden;
            border-radius: 10px 10px 0 0;
        }
        .card__img {
            width: 100%;
            border-radius: 10px 10px 0 0;
            transform: scale(105%);
            transition: 0.2s;
        }
        .card-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 8%;

            .card__description {
                padding: 5% 0;
                font-size: 0.7em;
                line-height: 150%;
                text-align: justify;
            }
            .card__data {
                font-size: 0.7em;
                padding: 0 0 10% 0;
                .data__item--bold {
                    font-weight: 600;
                }
            }
            .card__price {
                padding: 5px 8px;
                color: #fff;
                font-size: 0.8em;
                background-color: $main;
                border-radius: 12px;
            }
        }
    }
    .best-offers__card:hover {
        .card__img {
            transform: scale(110%);
        }
    }
}
@media (orientation: landscape) {
    .best-offers {
        margin-top: 10%;
        font-size: 18px;
        .best-offers__card {
            flex-direction: row;
            align-items: center;

            .hide {
                width: 100%;
                border-radius: 10px 0px 0 10px;
            }
            .card__img {
                width: 100%;
                border-radius: 10px 0px 0 10px;
            }
            .card-wrapper {
                padding: 3% 4%;
                font-size: 10px;
                position: relative;
                justify-content: center;
                align-self: stretch;
                justify-content: space-around;
                .card__description {
                    padding: 0;
                    font-size: 0.7em;
                    line-height: 150%;
                    text-align: justify;
                }
                .card__data {
                    padding: 0;
                }
                .card__price {
                    position: absolute;
                    bottom: 9%;
                    right: 6%;
                }
            }
        }
    }
}

@media (orientation: portrait) and (min-width: 700px) {
    .best-offers {
        font-size: 22px;
    }
}

@media (orientation: landscape) and (min-width: 600px) {
    .best-offers {
        .best-offers__card {
            .card-wrapper {
                font-size: 11px;
            }
        }
    }
}
@media (orientation: landscape) and (min-width: 700px) {
    .best-offers {
        .best-offers__card {
            .card-wrapper {
                font-size: 13px;
            }
        }
    }
}

@media (orientation: landscape) and (min-width: 1000px) {
    .best-offers {
        .best-offers__card {
            .card-wrapper {
                font-size: 15px;
            }
        }
    }
}

@media (orientation: landscape) and (min-width: 1300px) {
    .best-offers {
        margin-top: 55vh;
        font-size: 22px;
        .best-offers__card {
            margin-bottom: 8%;
            .hide {
                width: 100%;
            }
            .card__img {
                width: 100%;
            }
            .card-wrapper {
                font-size: 18px;
            }
        }
    }
}
@media (orientation: landscape) and (min-width: 1400px) {
    .best-offers {
        .best-offers__card {
            .card-wrapper {
                font-size: 22px;
            }
        }
    }
}
