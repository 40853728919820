.welcome {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .welcome__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/source/img/new-york-g63c960bfa_1920.jpg);
        background-size: 350%;
        background-position: center 30%;
        filter: brightness(50%);
        z-index: -1;
        background-repeat: no-repeat;
    }

    .welcome__slogan {
        font-family: $font;
        color: white;
        text-align: center;
        margin-bottom: 20%;
    }
    a:link,
    a:visited {
        text-decoration: none;
    }
    .welcome__contact {
        font-family: $font;
        color: $main;
        text-align: center;
        margin-bottom: 10%;
        font-size: 0.6em;
        font-weight: 500;
        background-color: #fff;
        padding: 9px 13px;
        border-radius: 19px;
    }

    .welcome__arrow {
        width: 100%;
        height: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10%;
        .arrow {
            position: relative;
            animation: arrow 1s infinite ease-in;

            .arrow__left {
                transform: rotate(45deg);
                left: -7px;
                @include arrows;
            }
            .arrow__right {
                transform: rotate(-45deg);
                left: 0;
                @include arrows;
            }
        }
        .arrow:nth-child(1) {
            opacity: 0.8;

            animation-delay: 0.2s;
        }
        .arrow:nth-child(2) {
            opacity: 0.8;

            animation-delay: 0.1s;
        }
        .arrow:nth-child(3) {
            opacity: 0.6;
            animation-delay: 0s;
        }
    }

    @keyframes arrow {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        50% {
            transform: translateY(5px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

@media (orientation: landscape) {
    .welcome {
        .welcome__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(/source/img/new-york-g63c960bfa_1920.jpg);
            background-size: cover;
            background-position: center 20%;
        }
        .welcome__slogan {
            margin-bottom: 50px;
        }
        .welcome__contact {
            margin-bottom: 20px;
        }
        .welcome__arrow {
            margin-bottom: 20px;
        }
    }
}

@media (orientation: landscape) and (min-width: 1000px) {
    .welcome {
        font-size: 20px;
        .welcome__image {
            background-size: cover;
            background-position: center 20%;
        }
        .welcome__slogan {
            margin-bottom: 20%;
        }

        .welcome__arrow {
            margin-bottom: 40px;
        }
    }
}

@media (orientation: landscape) and (min-width: 1300px) {
    .welcome {
        font-size: 25px;

        .welcome__slogan {
            margin-bottom: 10%;
            font-size: 1.8em;
        }
        .welcome__contact {
            margin-bottom: 30px;
        }
        .welcome__arrow {
            width: auto;

            transform: scale(120%);
        }
    }
}

@media (orientation: portrait) and (min-width: 760px) {
    .welcome {
        font-size: 25px;
        .welcome__contact {
            margin-bottom: 5%;
        }
        .welcome__arrow {
            margin-bottom: 5%;
        }
    }
}
